$(document).on('click', '.cloneButton', function (e) {
    e.preventDefault();
    var $container = $(this).data('container') ? $($(this).data('container')) : $('#clone-container');
    var $selector = $(this).data('selector') ? $(this).data('selector') : 'clone';
    var $prevContainer = $('.' + $selector, $container).last();
    var counter = $prevContainer.data('count');
    var prevCounter = counter;
    var $thisTemp = $('#' + $selector + '-' + prevCounter, $container);

    if ($thisTemp.hasClass('d-none')) {
        $thisTemp.find('.person-form-part').each(function (i, value) {
            $(value).prop('disabled', false);
        });
        $thisTemp.removeClass('d-none');
    } else {
        counter = $('.' + $selector + ':last', $container).length > 0
            ? parseInt($('.' + $selector + ':last', $container).data('count')) + 1
            : 0;

        var $template = $('#' + $selector + '-' + prevCounter, $container).clone();
        $template.find('.title-counter').text(counter+1);
        $template.data('count', counter);
        $template.attr('data-count', counter);
        $template.find('*[data-count]').each(function (i, value) {
            $(value).data('count', counter);
            $(value).attr('data-count', counter);
        });
        $template.find('*[data-index]').each(function (i, value) {
            $(value).data('index', counter);
            $(value).attr('data-index', counter);
        });
        $template.find('*[data-target]').each(function (i, value) {
            var newTarget = $(value).data('target').replace(prevCounter, counter);
            $(value).data('target', newTarget);
            $(value).attr('data-target', newTarget);
        });
        $template.attr('id', $selector + '-' + counter);
        $template.find('.subtotal-num').attr('id', 'ticket_'+counter).find('span').text('');

        $template.find('.remove-from-clone').remove();

        $template.find('*[name]').each(function (i, value) {
            if ($(value).attr('id')) {
                $(value).attr('id', $(value).attr('id').replace(prevCounter, counter));
            }
            var newName = $(value).attr('name').replace(prevCounter, counter);
            $(value).attr('name', newName)
        });
        $template.find('*[for]').each(function (i, value) {
            $(value).attr('for', $(value).attr('for').replace(prevCounter, counter));
            $(value).attr('id', $(value).attr('id').replace(prevCounter, counter));
        });
        $template.find('p.has-error').remove();
        $(':input:not(:button, :submit, :reset, [type="hidden"], :checkbox, :radio)', $template).val('').attr('value', '');
        $('.has-error', $template).removeClass('has-error');
        $('.remove-clone', $template).removeAttr('data-first');
        $('input', $template).not('.keep-value').attr('value', '').val();
        $('select', $template).not('.keep-value').val([]).find('option').removeAttr('selected');
        $(':checkbox, :radio', $template).prop('checked', false).removeAttr('checked');
        $('.count', $template).html((counter + 1) + '.');

        if ($('.remove-clone', $template).attr('data-id')) {
            $('.remove-clone', $template).attr('data-id', counter).show().attr('disabled', false);
        }
        $container.append($template);
    }
    ticket_events();
    return false;
});

$(document).on('click', '.remove-clone', function (e) {
    e.preventDefault();

    if ($(this).data('selector')) {
        var clone = $(this).data('selector')
    } else {
        var clone = 'clone'
    }

    var id = $(this).data('id')
    var first = $(this).data('first')
    if (first == true) {
        var $thisTemp = $('#' + clone + '-' + id);
        $thisTemp.addClass('d-none');
        $thisTemp.find('.person-form-part').each(function (i, value) {
            $(value).prop('checked', false);
            $(value).prop('disabled', true);
            $(value).val('');
        });
        // $('#' + clone + '-' + id).addClass('hide')
    } else {
        $('#' + clone + '-' + id).remove()
    }

    calcing_tickets();

    return false
})
